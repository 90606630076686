<template>
    <div>
        <div v-for="(item,index) in tableData" :key="index">
            <div style="margin-bottom: 10px;">{{item.TradeName}}</div>
            <el-table
                :data="item.FillSet"
                border
                style="width: 100%;margin-bottom: 10px;"
                v-loading="listLoading"
                ref="multipleTable"
                :highlight-current-row='true'
            >
                <el-table-column prop="TypeName" label="单位群体名称" align="center" min-width="250"></el-table-column>
                <el-table-column prop="StringValue" label="单位群体定义" align="center" min-width="400"></el-table-column>
                <el-table-column prop="IntValue" label="需填报人员数" align="center" min-width="200">
                    <template slot-scope="scope">
                        <el-input v-model="scope.row.IntValue" type="number"></el-input>
                    </template>
                </el-table-column>
                <el-table-column fixed="right" label="" align="center" width="120">
                    <template slot-scope="scope">
                        <el-button @click.native.prevent="formDataSubmit(scope.row)" type="text" size="small">
                            保存
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>

<script>
import { getButtonList } from "../../promissionRouter";
import Toolbar from "../../components/Toolbar";
import util from "../../../util/date";
import { getUnitFillingSetting,updateUnitFillCommonSetting } from "@/api/api"
export default {
    components: { Toolbar },
    data(){
        return {
            buttonList:[],
            tableData: [{
                TradeName: "11",
                FillSet: [{
                    TypeName: "22",
                    StringValue: "33",
                    IntValue: 44
                }]
            }],
            listLoading: false,
        }
    },
    methods:{
        formDataSubmit(val) {
            var _this = this
            var params = {
                Id: val.Id,
                IntValue: val.IntValue
            }
            updateUnitFillCommonSetting(params).then((res) => {
                var result = res.data
                if(result.Success) {
                    this.$message.success(result.Message)
                } else {
                    this.$message.error(result.Message)
                }
            })
        },
        getCommonDataFun(e) {
            var _this = this
            var params = {
                
            }
            getUnitFillingSetting(params).then(res => {
                var result = res.data
                if(result.Success) {
                    console.log("result",result)
                    this.tableData = result.Response.TradeSet
                }
            })
        }
    },
    created(){
        this.getCommonDataFun()
    },
    mounted(){
        let routers = window.localStorage.router
        ? JSON.parse(window.localStorage.router)
        : [];
        this.buttonList = getButtonList(this.$route.path, routers);
    }
}
</script>

<style lang="stylus" scoped>
    .el-textarea>>>.el-textarea__inner {
        min-height 150px!important;
    }
    .inputBottTitle {
        font-size: 14px;
        color: #888;
        margin-left: 153px;
        margin-top: -15px;
        display: block;
        padding-bottom: 15px;
    }
</style>